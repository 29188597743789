import { useMemo } from "react";
import { userDeposit } from "srcm/common/types/AdminInfo/userDeposit";

export const WITHDRAWAL_PAYMENT_METHOD_TYPE = 2;

interface IusePaymentMethods {
  currentMethod?: userDeposit;
}

export const usePaymentMethods = ({ currentMethod }: IusePaymentMethods) => {
  const manual = useMemo(
    () => currentMethod?.payment_name === "manual",
    [currentMethod]
  );
  const crypto = useMemo(
    () => currentMethod?.payment_aggregator_id === 3,
    [currentMethod]
  );
  const unixpay = useMemo(
    () => currentMethod?.payment_aggregator_id === 2,
    [currentMethod]
  );
  const payfix = useMemo(
    () => /payfix/.test(currentMethod?.name?.toLowerCase() || ""),
    [currentMethod]
  );
  const papara = useMemo(
    () => /papara/i.test(currentMethod?.name || ""),
    [currentMethod]
  );
  const bankHavalesi = useMemo(
    () => currentMethod?.name === "Banka Havalesi",
    [currentMethod]
  );

  const isShowWalletId = useMemo(
    () => payfix || crypto || unixpay || papara,
    [payfix, crypto, unixpay, papara]
  );

  const isShowExtraFields = useMemo(
    () => !unixpay && !crypto && !payfix && !manual,
    [unixpay, crypto, payfix, manual]
  );

  const fieldsVisibility = useMemo(
    () => ({
      bankCode: !bankHavalesi && isShowExtraFields,
      accountNumber: !bankHavalesi && isShowExtraFields,
      id: !bankHavalesi && isShowExtraFields,
      notes: !bankHavalesi && isShowExtraFields,
      description: !bankHavalesi && isShowExtraFields,
      walletId: isShowWalletId,
    }),
    [bankHavalesi, isShowExtraFields, isShowWalletId]
  );

  const isWithExtraData = useMemo(
    () => isShowWalletId || isShowExtraFields,
    [isShowWalletId, isShowExtraFields]
  );

  return {
    manual,
    crypto,
    unixpay,
    payfix,
    isWithExtraData,
    fieldsVisibility,
  };
};
