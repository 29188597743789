import React from "react";

import "./WitdhrowExtraData.scss";
import {
  AccountNumberInput,
  AddNotesInput,
  AmountInput,
  FullNameInput,
  BankCodeInput,
  IbanInput,
  IdInput,
  LastCharsInput,
  DescriptionInput,
  WalletIdInput,
} from "./UI";
import { userDeposit } from "srcm/common/types/AdminInfo/userDeposit";
import { usePaymentMethods } from "srcm/hooks/usePaymentMethods";

interface IWitdhrowExtraData {
  bankCodeValue?: string | null;
  setBankCodeValue?: React.Dispatch<React.SetStateAction<string | null>>;
  fullNameValue?: string | null;
  setFullNameValue?: React.Dispatch<React.SetStateAction<string | null>>;
  accountNumberValue?: string | null;
  setAccountNumberValue?: React.Dispatch<React.SetStateAction<string | null>>;
  ibanValue?: string | null;
  setIbanValue?: React.Dispatch<React.SetStateAction<string | null>>;
  lastCharsValue?: string | null;
  setLastCharsValue?: React.Dispatch<React.SetStateAction<string | null>>;
  idValue?: string | null;
  setIdValue?: React.Dispatch<React.SetStateAction<string | null>>;
  addNotesValue?: string | null;
  setAddNotesValue?: React.Dispatch<React.SetStateAction<string | null>>;
  descriptionValue?: string | null;
  setDescriptionValue?: React.Dispatch<React.SetStateAction<string | null>>;
  walletIdValue?: string | null;
  setWalletIdValue?: React.Dispatch<React.SetStateAction<string | null>>;
  payment_name?: string;
  payment_aggregator_id?: number;
  id?: number;
  inputValue: string;
  setInputValue: React.Dispatch<React.SetStateAction<string>>;
  currentMethod?: userDeposit;
}

const WitdhrowExtraData = ({
  bankCodeValue,
  setBankCodeValue,
  fullNameValue,
  setFullNameValue,
  ibanValue,
  setIbanValue,
  lastCharsValue,
  setLastCharsValue,
  idValue,
  setIdValue,
  addNotesValue,
  setAddNotesValue,
  inputValue,
  setInputValue,
  payment_name,
  payment_aggregator_id,
  id,
  accountNumberValue,
  setAccountNumberValue,
  descriptionValue,
  setDescriptionValue,
  walletIdValue,
  setWalletIdValue,
  currentMethod,
}: IWitdhrowExtraData) => {
  const { unixpay, manual, crypto, payfix, fieldsVisibility } =
    usePaymentMethods({ currentMethod });
  return (
    <>
      {fieldsVisibility.accountNumber && (
        <AccountNumberInput
          accountNumberValue={accountNumberValue}
          setAccountNumberValue={setAccountNumberValue}
        />
      )}
      {/* {fieldsVisibility.addNotes && (
        <AddNotesInput
          addNotesValue={addNotesValue}
          setAddNotesValue={setAddNotesValue}
        />
      )} */}
      <AmountInput inputValue={inputValue} setInputValue={setInputValue} />
      {/* {fieldsVisibility.fullName && (
        <FullNameInput
          fullNameValue={fullNameValue}
          setFullNameValue={setFullNameValue}
        />
      )} */}
      {fieldsVisibility.bankCode && (
        <BankCodeInput
          bankCodeValue={bankCodeValue}
          setBankCodeValue={setBankCodeValue}
        />
      )}
      {!unixpay && !payfix && !crypto && !manual && (
        <IbanInput ibanValue={ibanValue} setIbanValue={setIbanValue} />
      )}
      {fieldsVisibility.id && (
        <IdInput idValue={idValue} setIdValue={setIdValue} />
      )}
      {/* {fieldsVisibility.lastChars && (
        <LastCharsInput
          lastCharsValue={lastCharsValue}
          setLastCharsValue={setLastCharsValue}
        />
      )} */}
      {fieldsVisibility.description && (
        <DescriptionInput
          descriptionValue={descriptionValue}
          setDescriptionValue={setDescriptionValue}
        />
      )}
      {fieldsVisibility.walletId && (
        <WalletIdInput
          walletIdValue={walletIdValue}
          setWalletIdValue={setWalletIdValue}
        />
      )}
    </>
  );
};

export { WitdhrowExtraData };
