import React, { useState } from "react";

import "./AccountNumberInput.scss";

interface IAccountNumberInput {
  accountNumberValue?: string | null;
  setAccountNumberValue?: React.Dispatch<React.SetStateAction<string | null>>;
}

const AccountNumberInput = ({
  accountNumberValue,
  setAccountNumberValue,
}: IAccountNumberInput) => {
  const [inputFocused, setInputFocused] = useState(false);

  const onChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    setAccountNumberValue && setAccountNumberValue(e.target.value);
  };

  const handleFocus = () => {
    setInputFocused(true);
  };

  const handleBlur = () => {
    if (accountNumberValue && accountNumberValue.length === 0) {
      setInputFocused(false);
    } else {
      setInputFocused(true);
    }
  };
  return (
    <div className="u-i-p-control-item-holder-bc">
      <div
        className={`form-control-bc default ${inputFocused ? "focused" : ""}`}
      >
        <label className="form-control-label-bc inputs">
          <input
            type="text"
            inputMode="decimal"
            className="form-control-input-bc"
            name="name"
            step={0}
            defaultValue=""
            value={accountNumberValue || ""}
            onChange={onChangeInput}
            onFocus={handleFocus}
            onBlur={handleBlur}
          />
          <i className="form-control-input-stroke-bc" />
          <span className="form-control-title-bc ellipsis">Account Namber</span>
        </label>
      </div>
    </div>
  );
};

export { AccountNumberInput };
