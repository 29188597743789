import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { AdminPanel } from "services";
import "./FilterBetHistory.scss";
import load from "../../../../../../../accets/images/gif/loader/loader.gif";
import { t } from "i18next";
import { BetPeriod, BetType } from "../ui";
import {
  userBetsDataType,
  userBetsMetaType,
} from "common/types/AdminInfo/userBets";

const FilterBetHistory = () => {
  const [selectOpen, setSelectOpen] = useState(false);
  const [searchParams] = useSearchParams();
  const page = searchParams.get("page") || "";

  const [betType, setBetType] = useState("");
  const [statusType, setStatusType] = useState("");

  const [period, setPeriod] = useState("24/1");
  const [dateRange, setDateRange] = useState("");

  const [loadBets, setLoadBets] = useState(false);
  const [data, setData] = useState<userBetsDataType[] | null>(null);
  const [paginationInfo, setPaginationInfo] = useState<userBetsMetaType | null>(
    null
  );
  const [paginationPage, setPaginationPage] = useState(1);
  const [expandedRow, setExpandedRow] = useState<number | null>(null);

  const handleShow = () => {
    async function getBets() {
      setLoadBets(true);
      try {
        const res = await AdminPanel.userBets(
          `?date=${dateRange}&type=${betType}&status=${statusType}&limit=10&page=${paginationPage}`
        );
        setData(res.data.data);
        setPaginationInfo(res.data.meta);
        setLoadBets(false);
      } catch {
        setLoadBets(false);
      }
    }
    getBets();
  };

  useEffect(() => {
    handleShow();
  }, [paginationPage]);

  const handleNext = () => {
    if (paginationPage < Math.ceil(Number(paginationInfo?.total) / 10)) {
      setPaginationPage((prevPage) => prevPage + 1);
    }
  };

  const handlePrevious = () => {
    if (paginationPage > 1) {
      setPaginationPage((prevPage) => prevPage - 1);
    }
  };

  const toggleDetails = (id: number) => {
    setExpandedRow((prev) => (prev === id ? null : id));
  };

  return (
    <div
      className="u-i-e-p-p-content-bc u-i-common-content"
      data-scroll-lock-scrollable=""
    >
      <div className="componentFilterWrapper-bc">
        <div
          className={`componentFilterLabel-bc ${selectOpen ? "active" : ""}`}
          onClick={() => setSelectOpen((prev) => !prev)}
        >
          <i className="componentFilterLabel-filter-i-bc bc-i-filter" />
          <div className="componentFilterLabel-filter-bc">
            <p className="ellipsis">{t("admin.Filter")}</p>
          </div>
          <i className="componentFilterChevron-bc bc-i-small-arrow-down" />
        </div>
        <div className="componentFilterBody-bc">
          <div className="componentFilterElsWrapper-bc">
            <div className="filter-form-w-bc">
              <BetType
                betType={betType}
                setBetType={setBetType}
                setPaginationPage={setPaginationPage}
              />
              <BetPeriod />
              <div className="u-i-p-c-footer-bc">
                <button
                  className="btn a-color"
                  type="submit"
                  onClick={handleShow}
                >
                  <span>{t("admin.Show")}</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loadBets ? (
        <div className="bc-loader medium">
          <div className="bc-loader-contain">
            <img src={load} alt="Loading" />
          </div>
        </div>
      ) : (
        <>
          {data ? (
            <div className="table-container">
              <table className="custom-table">
                <thead className="table-header">
                  <tr>
                    <th className="header-cell">Date</th>
                    <th className="header-cell">Game</th>
                    <th className="header-cell">Amount Of Bet</th>
                    <th className="header-cell">Win Amount</th>
                    <th className="header-cell">Currency</th>
                    <th className="header-cell">Status</th>
                    <th className="header-cell">More Detail</th>
                  </tr>
                </thead>
                <tbody className="table-body">
                  {data.map((item) => (
                    <React.Fragment key={item.id}>
                      <tr className="table-row">
                        <td className="table-cell">
                          {new Date(item.date).toLocaleString()}
                        </td>
                        <td className="table-cell">{item.game_name}</td>
                        <td className="table-cell">
                          {item.bet_amount.toFixed(2)}
                        </td>
                        <td className="table-cell">
                          {item.win_amount.toFixed(2)}
                        </td>
                        <td className="table-cell">{item.currency}</td>
                        <td className="table-cell">{item.status}</td>
                        <td className="table-cell">
                          <button
                            className="details-button"
                            onClick={() => toggleDetails(item.id)}
                          >
                            More Detail
                          </button>
                        </td>
                      </tr>
                      {expandedRow === item.id && item.bet_details && (
                        <tr className="table-row details-row">
                          <td className="table-cell" colSpan={7}>
                            <table className="details-table">
                              <thead>
                                <tr>
                                  <th>Sport</th>
                                  <th>Teams</th>
                                  <th>League</th>
                                  <th>Market</th>
                                  <th>Odds</th>
                                  <th>Price</th>
                                </tr>
                              </thead>
                              <tbody>
                                {item.bet_details.map((detail) => (
                                  <tr key={detail.id}>
                                    <td>{detail.event.sport}</td>
                                    <td>
                                      {detail.event.team1} vs {detail.event.team2}
                                    </td>
                                    <td>{detail.event.league}</td>
                                    <td>{detail.market.name}</td>
                                    <td>{detail.rate}</td>
                                    <td>{detail.price}</td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </td>
                        </tr>
                      )}
                    </React.Fragment>
                  ))}
                </tbody>
              </table>
              <div className="pagination">
                <button
                  className="pagination-button"
                  onClick={handlePrevious}
                  disabled={paginationPage <= 1}
                >
                  &lt;
                </button>
                <span className="pagination-info">
                  {paginationPage} of {Math.ceil(Number(paginationInfo?.total) / 10)}
                </span>
                <button
                  className="pagination-button"
                  onClick={handleNext}
                  disabled={
                    paginationPage >= Math.ceil(Number(paginationInfo?.total) / 10)
                  }
                >
                  &gt;
                </button>
              </div>
            </div>
          ) : (
            <p className="empty-b-text-v-bc">{t("admin.Nobetstoshow")}</p>
          )}
        </>
      )}
    </div>
  );
};

export { FilterBetHistory };
